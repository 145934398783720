const jobData = [

    {
        id: 1,
        mainId: 'Salesman',
        title: 'Salesman',
        description: 'High school diploma or equivalent; Bachelor degree in Sales, Marketing, or a related field is preferred. Proven experience in sales, with a track record of meeting or exceeding sales targets. Minimum of two years of experience as a Salesman or in a similar role. Strong communication and interpersonal skills, with the ability to build rapport and establish trust with customers. Excellent negotiation and persuasion abilities, with a results-driven mindset. Ability to work independently and as part of a team in a fast-paced environment. Proficiency in using sales management software and CRM tools to track leads, opportunities, and customer interactions. Adaptability and resilience to handle rejection and overcome objections in the sales process. Willingness to continuously learn and adapt to evolving market trends and customer preferences. Previous experience in a retail or B2B sales environment. Industry-specific knowledge or expertise relevant to the products or services being sold.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '30K to above',
        projectType: 'onside',
        skills: ['Sale Techniques , Negotiating intersections , Managing Time, Safe driving , Resilience , Effective communication , a deep understanding of the product or service being sold, and the ability to analyze and interpret data. '],
        Link: '/jobPlace1',
      },
      {
        id: 2,
        mainId: 'Cashier',
        title: 'Cashier',
        description: 'High school diploma or equivalent.Minimum of two years of experience as a Cashier or in a similar retail role. Proficiency in operating a cash register and handling cash transactions. Strong numerical aptitude and attention to detail. Excellent interpersonal and communication skills. Ability to work effectively in a fast-paced environment and multitask. Basic math skills and knowledge of arithmetic calculations. Familiarity with point-of-sale (POS) systems and electronic payment processing. Experience in a high-volume retail environment. Knowledge of product merchandising and display techniques.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '50K to above',
        projectType: 'onside',
        skills: ['Attention to detail , Customer service , Technical proficiency , Numerical skills , Problem-solving skills , Accuracy , Time management , Communication skills , Stress management , Integrity'],
        Link: '/jobPlace2',
      },
      {
        id: 3,
        mainId: 'Computer Operator',
        title: 'Computer Operator',
        description: 'High school diploma or equivalent qualification. Minimum of two years of experience as a Computer Operator or in a similar role.  Proficiency in Microsoft Office Suite (Word, Excel, PowerPoint, Outlook). Strong typing and data entry skills with a high level of accuracy. Excellent organizational and time management abilities. Attention to detail and commitment to maintaining data integrity. Basic knowledge of computer hardware, software, and networking concepts. Effective communication skills, both verbal and written. Certification in Microsoft Office Specialist (MOS) or equivalent. Familiarity with database management systems (e.g., Microsoft Access). Experience with document management software or electronic filing systems.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '30K to above',
        projectType: 'onside',
        skills: ['proficiency in Microsoft Office , Technical Expertise , Attention to detail , Problem solving , Adaptability , Organizational skills , Communication skills , Teamwork , Time management , Attention to Security'],
        Link: '/jobPlace3',
      },
      {
        id: 4,
        mainId: 'Social Media Work',
        title: 'Social Media Work',
        description: 'Bachelor degree in Marketing, Communications, or a related field. Minimum of two year of experience in social media management.Proficiency in using social media management tools such as Hootsuite, Buffer, or Sprout Social. Strong copywriting skills with a keen eye for detail and grammar. Creative mindset with the ability to think outside the box and generate innovative ideas. Excellent communication skills, both written and verbal. Knowledge of social media trends, best practices, and emerging platforms.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '35K to above',
        projectType: 'onside',
        skills: ['Content creation, Copywriting, Visual Design, Video Editing, Community Management, Analytics and Insights, Strategic Planning, Campaign Management, Influencer marketing, Customer realtionship management(CRM),SEO and Keyword Research, Creativity and Innovation'],
        Link: '/jobPlace4',
      },
      {
        id: 5,
        mainId: 'Supervisor (Store incharge)',
        title: 'Supervisor (Store incharge)',
        description: 'High school diploma or equivalent; Bachelor degree in Business Administration or related field is preferred. Minimum of two years of experience in a supervisory role in retail or a similar industry. Strong leadership and communication skills, with the ability to motivate and inspire team members. Excellent organizational and time management abilities, with a focus on prioritization and delegation. Knowledge of retail operations, including inventory management, merchandising, and customer service best practices. Proficiency in using point-of-sale (POS) systems and other retail management software. Ability to work flexible hours, including evenings, weekends, and holidays, as required by business needs.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '50K to above',
        projectType: 'onside',
        skills: ['Leadership, Communication, Organizational skills, Customer service, Inventory management, Salesmanship, Team management, Safty and Security, Computer skills'],
        Link: '/jobPlace5',
      },
      {
        id: 6,
        mainId: 'Supervisor (Sale incharge)',
        title: 'Supervisor (Sale incharge)',
        description: 'Bachelor degree in Business Administration, Marketing, or a related field. Minimum of two years of experience in a supervisory or managerial role in sales. Proven track record of meeting or exceeding sales targets and driving revenue growth. Strong leadership and interpersonal skills, with the ability to inspire and motivate a team. Excellent communication and negotiation abilities, with a customer-focused approach. Strategic thinker with analytical skills and problem-solving capabilities. Proficiency in using CRM software and other sales management tools. Ability to work well under pressure and adapt to changing business requirements. Experience in the industry or sector relevant to the products or services being sold. Knowledge of digital marketing and e-commerce trends.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '50K to above',
        projectType: 'onside',
        skills: ['Sales leadership, Customer Relationship Management(CRM), Product knowledge, Sales Analytics, Negotiation skills, Financial Acumen, Results-Oriented '],
        Link: '/jobPlace6',
      },
      {
        id: 7,
        mainId: 'Supervisor (Account incharge)',
        title: 'Supervisor (Account incharge)',
        description: 'Bachelor degree in Accounting, Finance, or a related field; CPA or equivalent certification preferred. Minimum of two years of experience in a supervisory or managerial role in accounting or finance. Thorough understanding of accounting principles, practices, and standards (e.g., GAAP).Proficiency in accounting software and ERP systems; experience with QuickBooks, SAP, or Oracle is desirable. Strong analytical and problem-solving skills, with attention to detail and accuracy.Excellent communication and interpersonal skills, with the ability to interact effectively with stakeholders at all levels. Leadership abilities, including team-building, coaching, and performance management. Ability to prioritize tasks, meet deadlines, and work effectively under pressure. Experience in a regulated industry or public accounting firm. Familiarity with international accounting standards (IFRS) and multi-currency environments.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '60K to above',
        projectType: 'onside',
        skills: ['Financial management, Accounting knowledge, Bookkeeping, Financial reporting, Audit and Compliance, Taxation, Financial Planning and Analysis, Software proficiency, Ethical Conduct'],
        Link: '/jobPlace7',
      },
      {
        id: 8,
        mainId: 'OutDoor Worker',
        title: 'OutDoor Worker',
        description: 'High school diploma or equivalent. Minimum of two years of experience in outdoor work, landscaping, construction, or a related field.Physical stamina and endurance to perform manual labor outdoors in various weather conditions. Familiarity with outdoor equipment, tools, and machinery commonly used in landscaping, maintenance, and construction work. Ability to follow instructions, work independently, and prioritize tasks effectively. Strong attention to detail and commitment to quality and safety standards. Valid driver license and clean driving record (if required for the position). Willingness to work flexible hours, including weekends and holidays, as needed.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '35K to above',
        projectType: 'onside',
        skills: ['Physicaal stamina, Health and Safetly awareness, Manual dexterity, Mechanical skills'],
        Link: '/jobPlace8',
      },
      {
        id: 9,
        mainId: 'Store Worker',
        title: 'Store Worker',
        description: 'High school diploma or equivalent/ Minimum of two years of experience in a retail or warehouse environment. Familiarity with inventory management systems and procedures. Strong customer service skills, with a friendly and approachable demeanor. Ability to work effectively in a fast-paced environment and multitask. Attention to detail and accuracy in handling transactions and inventory. Basic math skills and proficiency in using cash registers and POS systems. Physical stamina and ability to lift and carry heavy objects as needed. Knowledge of product merchandising and display techniques. Bilingual proficiency in languages commonly spoken by customers in the area.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '30K to above',
        projectType: 'onside',
        skills: ['Customer Service, Computer skills, Flexibility, Reliabilty, Customer focus, Product Knowledge, Safety awareness. '],
        Link: '/jobPlace9',
      },
      {
        id: 10,
        mainId: 'Loader Rickshaw Driver',
        title: 'Loader Rickshaw Driver',
        description: 'Valid driver license (RLicense). Minimum of one year of experience as a loader rickshaw driver or in a similar role. Knowledge of traffic regulations and safe driving practices.Physical stamina and strength to lift and move heavy objects. Good hand-eye coordination and spatial awareness. Ability to work independently and follow instructions. Excellent communication skills and a professional demeanor. Willingness to work flexible hours, including evenings, weekends, and holidays, as needed.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '30K to above + commission',
        projectType: 'onside',
        skills: ['Responder, Driving skills, Outdoor Education, Vehical management,Load management, Route planning, Customer service, Safety awareness'],
        Link: '/jobPlace10',
      },
      {
        id: 11,
        mainId: 'Labour for store',
        title: 'Labour for store',
        description: 'High school diploma or equivalent.Minimum of one year of experience in a retail or similar environment. Ability to work effectively in a fast-paced and team-oriented environment. Strong communication and interpersonal skills, with a customer-focused attitude. Physical stamina and endurance to perform manual tasks, including lifting, bending, and standing for extended periods. Attention to detail and commitment to maintaining cleanliness and organization.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '30K to above',
        projectType: 'onside',
        skills: ['Physical strength and Stamina, Manual dexterity, Communication, Team management, Reliability, Customer focus, Flexibility, Basic maths skills, Positive attitude'],
        Link: '/jobPlace11',
      },
      {
        id: 12,
        mainId: 'Accountent',
        title: 'Accountent',
        description: 'Bachelor degree in Accounting, Finance, or a related field. Minimum of two years of experience in accounting or finance roles. Proficiency in accounting software and ERP systems; experience with QuickBooks, SAP, or Oracle is desirable. Strong analytical and problem-solving skills, with attention to detail and accuracy.Excellent communication and interpersonal skills, with the ability to interact effectively with colleagues and external stakeholders. Knowledge of accounting principles, practices, and standards (e.g., GAAP). Ability to work independently and as part of a team, prioritize tasks, and meet deadlines. CPA or equivalent certification is a plus.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '40K to above',
        projectType: 'onside',
        skills: ['Accounting principles, Financial analysis, Financial reporting, Bookkeeping, Texation, Auditing, Financial management, Ethical conduct, Continuous learning '],
        Link: '/jobPlace12',
      },
      {
        id: 13,
        mainId: 'Digital Saleman',
        title: 'Digital Saleman',
        description: 'Bachelor degree in Marketing, Business Administration, or a related field. Minimum of two years of experience in digital sales or a similar role. Proven track record of success in driving sales revenue through digital channels. Strong understanding of digital marketing principles and techniques. Excellent communication and presentation skills, with the ability to articulate complex concepts clearly and persuasively. Analytical mindset with proficiency in analyzing data and interpreting performance metrics. Self-motivated and results-driven, with the ability to work independently and as part of a team. Familiarity with CRM software and digital sales tools is preferred.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '35K to above',
        projectType: 'onside',
        skills: ['Digital marketing knowlwdge, Sales skills, Customer relationship Management(CRM), Social media savvy, Data analysis, Digital tools and plateform, Teach-savviness '],
        Link: '/jobPlace13',
      },
      {
        id: 14,
        mainId: 'Graphic Designer',
        title: 'Graphic Designer',
        description: 'Bachelor degree in Graphic Design, Visual Communication, or a related field. Minimum of two years of experience in graphic design or a similar role. Portfolio showcasing a diverse range of design projects and styles, demonstrating creativity, technical proficiency, and attention to detail. Strong understanding of design principles, typography, color theory, and composition. Excellent communication and collaboration skills, with the ability to effectively communicate ideas and feedback to team members and clients. Time management and organizational skills, with the ability to manage multiple projects and meet deadlines in a fast-paced environment. Adaptability and willingness to learn new techniques and software tools as needed.',
        location: ' 8 nafees paper market near Urdu bazar lahore Punjab Pakistan',
        company: 'Madni paper lahore',
        jobType: 'Full-time',
        salary: '40K to above',
        projectType: 'onside',
        skills: ['Graphic design softwre proficiency, Typography, Color theory, Composition, Layout design, Digital illustration, Photo editing, Branding and identity '],
        Link: '/jobPlace14',
      },

]
export default jobData;