import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  
  return (
    <div>
    {/* Topbar Start */}
<div className="container-fluid bg-light ">
  <div className="row ">
    <div className="col-lg-7 col-md-12 pe-5 text-start">
      <div className='mt-3'>
      <div className=" d-inline-flex align-items-center py-1 me-4">
        <small className="fa fa-map-marker-alt text-primary me-2" />
        <small>8 nafees paper market near Urdu bazar lahore Punjab Pakistan</small>
      </div>
      <div className=" d-inline-flex align-items-center py-1">
        <small className="far fa-clock text-primary me-2" />
        <small>Mon - Sat : 10:00 AM - 07:00 PM</small>
      </div></div>
    </div>
    <div className="col-lg-5 col-md-12 px-5 text-end">
      <div className="h-100 d-inline-flex align-items-center py-1 me-4 mt-2">
      <p className='fs-5'>Online purchasing available.
 
      <Link to="https://wa.me/923178887957" target="_blank" rel="noopener noreferrer"><button type="button" className="btn btn-primary ms-2">Order Now</button></Link>
  
    </p>
      </div>
     
    </div>
  </div>
</div>
{/* Topbar End */}

     <div className='container'>
     <header class="blog-header py-3">
        <div class="row flex-nowrap justify-content-between align-items-center">
          <div class="col-4 pt-1">
          <button className="btn btn-sm ms-2 justify-content-center navtogglebotton " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" >   <i class="fa-solid fa-bars ms-4 fs-4 mt-3 " id='bar'></i></button>
          <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex={-1} id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel"> <Link to="/"> <img src="./assets/logo.png" class="img-fluid " alt="logo" height={"60px"} width={"180px"} style={{marginLeft:"80px"}}/> </Link></h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
        <div className="offcanvas-body">
        <ul>
           <Link  className='text' to="/"><li><i class="fa-solid fa-house"></i> Home</li></Link>
                <hr/>
          <Link   className='text' to="/about"> <li><i class="fa-solid fa-circle-exclamation"></i>  About</li></Link><hr/>
            <Link  className='text' to="/service"><li><i class="fa-brands fa-servicestack"></i> Service</li></Link><hr/>
            <Link  className='text' to="/contact"><li><i class="fa-solid fa-user"></i> Contact us</li></Link><hr/>
            {/* <Link  className='text' to="/gallery"><li><i class="fa-brands fa-pix"></i> Gallery</li></Link><hr/> */}
            {/* <Link  className='text' to="/award"><li><i class="fa-solid fa-award"></i> Award</li></Link><hr/> */}
            <Link  className='text' to="/career"><li><i class="fa-brands fa-google-scholar"></i> Career</li></Link><hr/>
            <li>
             <Link   to="https://www.facebook.com/madnipapermart"> <i class="fa-brands fa-facebook-f me-4 text-dark"></i></Link>
            <Link to="https://www.instagram.com/madnipapermart"><i class="fa-brands fa-square-instagram me-3 text-dark"></i></Link>
            <Link to="https://www.linkedin.com/in/madni-paper-mart/"><i class="fa-brands fa-linkedin-in text-dark"></i></Link>
            </li>
        </ul>
    </div>
  </div>
          </div>
          <div class="col-8 text-center">
            <Link class="blog-header-logo text-dark" to="/"><img src="./assets/Na.png" class="img-fluid " alt="logo" style={{ marginLeft:"-75px"}}/></Link>
          </div>
          {/* <div class="col-4 d-flex justify-content-end align-items-center">
            <a class="text-muted" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mx-3"><circle cx="10.5" cy="10.5" r="7.5"></circle><line x1="21" y1="21" x2="15.8" y2="15.8"></line></svg>
            </a>
            <a class="btn btn-sm btn-outline-secondary" href="#">Sign up</a>
          </div> */}
        </div>
      </header>
</div>


     



   </div>
  )
}

export default Navbar
