import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../Components/Layout'
import { MDBContainer, MDBTable, MDBTableBody } from 'mdbreact';
import jobData from '../Data/jobData'
import { Link} from 'react-router-dom';
import Button from '../Components/Button';


const CareerPage = () => {
  const [data, setData] = useState([])
  const [record, setRecord] = useState([])

  useEffect(() => {
    setData(jobData)
    setRecord(jobData)
    axios.get('../Data/jobData.js')

      .catch(err => console.log(err))
  }, [])
  const Filter = (event) => {
    setRecord(data.filter(f => f.title.toLowerCase().includes(event.target.value)))

  }

  return (
    <Layout>
     <Button/>
      <div>
        <MDBContainer>
          <MDBTable responsive  >
            <MDBTableBody>
           <div className='container mt-5'>

              <input type='text' className='form-control rounded-pill' onChange={Filter} placeholder='   Type here to search ' style={{border:"1px solid black"}} />
           </div>
           <br/>
              {/* <input type='text' className='' onChange={(e) => setResult(e.target.value)}/> */}
              {/* #aaf0c9 */}
              {
                record.map((i) => (
                  <tr key={i.id}>
                    <td>
                      <div className="card" style={{backgroundColor:"#daf0ff"}}>
                        <div className="card-body">
                          <h5 className="card-title">{i.title}</h5>
                          <p className="card-text">{i.company}</p>
                          <p className="card-text"><i className="fa-solid fa-money-bill-1-wave me-1"></i>{i.salary}</p>
                          <p className="card-text"><i className="fa-solid fa-location-crosshairs"></i>{i.location}</p>
                          <p className="card-text">Skills: {`${i.skills.slice(0, 100)}...`}</p>
                          <p className="card-text">Requirements:  {`${i.description.slice(0, 200)}...`}</p>
                          <Link to={i.Link} className="btn btn-primary read_more_btn">Job Detail</Link>

                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>
        </MDBContainer>
      </div>
      {/*  */}
     

    </Layout>
  );
};




export default CareerPage;
