import React, { useEffect } from 'react';
import Layout from '../Components/Layout';
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';
import Button from '../Components/Button';
import card from '../Pages/Cardsapi';
import Aos from 'aos';

export default function Home(props) {
  const award =[
    {  image: './assets/team/1.jpg' },
    {  image: './assets/team/1.jpg' },
    {  image: './assets/team/1.jpg' },
    {  image: './assets/team/1.jpg' },
  ]
  useEffect(() => {
    Aos.init({ duration: 2000});
    
  }, [])
  return (
    <Layout>
        <Button/>
      
        <header className='parallex-header ' id='parallex'>
{/* container */}
<div className='container'>
  <div className='header-content'>
  

<h1 className='text-center text-light fs-7' id='homepar' > Offer : 20%</h1>
  
  </div>

</div>
{/* end of container */}
  </header>

  <div className='container-fluid  my-3'>
          <Marquee>                     
                        <img src="./assets/logos/1.png" alt='img'/>
                        <img src="./assets/logos/2.png" alt='img' />
                        <img src="./assets/logos/3.png" alt='img'/>
                        <img src="./assets/logos/4.png" alt='img' />
                        <img src="./assets/logos/5.png" alt='img' />
                        <img src="./assets/logos/6.png" alt='img'/>
                        <img src="./assets/logos/7.png" alt='img' />
                        <img src="./assets/logos/8.png" alt='img' />
                        <img src="./assets/logos/9.png" alt='img' />
                        <img src="./assets/logos/10.png" alt='img' />
                        <img src="./assets/logos/12.png" alt='img' />
                        <img src="./assets/logos/13.png" alt='img'/>
                        <img src="./assets/logos/14.png" alt='img' />
                        <img src="./assets/logos/15.png" alt='img' />
                        <img src="./assets/logos/16.png" alt='img'/>
                        <img src="./assets/logos/17.png" alt='img' />
                        <img src="./assets/logos/18.png" alt='img' />
                        <img src="./assets/logos/19.png" alt='img' />
                        <img src="./assets/logos/20.png" alt='img' />  
           </Marquee>
        </div>

      {/* cards start here */}
<h2 className='heading text-center m-5 fs-1'><span style={{color:"#02672B"}}>Our</span> Service</h2>
      <div  class="container-fluid d-flex align-items-center justify-content-center position-relative flex-wrap" id="body">
      {card.map((ele, index) => (
        <div key={index} class="card d-flex position-relative flex-column">
            <div class='imgContainer'>
                <img src={ele.imgsrc}  alt='img'/>
            </div>
            <div class="content">
                <h3 style={{textAlign:"center"}}>{ele.heading}</h3>
                <p>{ele.paragraph}</p>
                <Link to={`/${ele.id}`}>
  <button type="button" className="btn btn-info" id='btn'>
    Explore More
  </button>
</Link>
            </div>
        </div>))}

    </div>
    
    {/* cards end here */}

    {/* why choose us */}

<section className="about_section layout_padding mt-5" >
  <div className="container  " style={{marginTop:"-50px", marginBottom:"50px"}}>
    <div className="row ">
      <div data-aos="fade-up"  className="col-md-6">
        <div className="detail-box">
          <div className="heading_container" style={{color:"#02672B", marginTop:"100px"}}>
            <h2>Why Choose Us?</h2>
          </div>
          <p>
          Choosing Madni Paper Mart means choosing quality, reliability, and efficiency for all your paper product needs. With a commitment to excellence ingrained in every aspect of our operations, we pride ourselves on delivering superior products that exceed expectations. Our extensive range of paper products caters to diverse requirements, ensuring we have solutions for every need. Backed by years of industry experience, we understand the importance of timely delivery and cost-effectiveness, making us the preferred choice for countless satisfied clients. Trust Madni Paper Mart for unparalleled quality, service, and value. Choose us, and experience excellence in every sheet.
          </p>
   
        
        </div>
      </div>
      <div data-aos="fade-up" className="col-md-6  ">
        <div className="img-box">
          <img src="./assets/about/why.png" alt='img' style={{width:"600px",height:"500px"}} />
        </div>
      </div>
    </div>
  </div>
</section>

<div className='container-fluid' style={{backgroundColor:"#00ABF2"}}>
<div className='row p-5'>
    <div className='col-md-10'>
        <div className='heading'>
<h2 className='text-white'>LOOKING FOR QUALITY MADNI PAPER COMPANY</h2>
<p className='fs-5'>We provide Different High Quality Paper Services. GET A QUOTE IN 20% OFF</p>
        </div>

    </div>
    <div className='col-md-2'>
        <div className='quote'>
        <Link to="/contact">
            GET A QUOTE
          </Link>
        </div>

    </div>
</div>
</div>
{/* <h2 className='heading text-center mt-5 fs-1'><span style={{color:"#02672B"}}>Our</span> Awards</h2> */}
{/* award  map*/}
{/* <div className='container'>
            <div className="row">
              {award.map((ele, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-5">
                  <div data-aos="zoom-in-up" className="custom-card card" id="card" style={{ width: '100%' }}>
          
                    <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
                    
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          {/* end map */}
          {/* <div className='text-center mt-2'>
         <Link to="/award"> <button className='btn btn-success p-2 fs-5'>See more</button></Link>

          </div> */}
    </Layout>
  );
}
