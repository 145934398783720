import React from 'react'
import Layout from '../Components/Layout'
import Button from '../Components/Button'
import card from './Cardsapi'
import { Link } from 'react-router-dom'

const Service = () => {
  
  return (
    <Layout>
      <Button/>
        {/* Page Header Start */}
<div className="container-fluid page-header py-5 mb-5" id='service'>
  <div className="container py-5 ">
  <div data-aos="fade-down">

    <h1 className="display-3 text-light mb-3 animated slideInDown text-center">Services</h1>
  </div>
  <div data-aos="fade-right">

    <nav aria-label="breadcrumb  animated slideInDown"  style={{ display: 'flex', justifyContent: 'center' }}>
      <ol className="breadcrumb text-light  ">
        <li className="breadcrumb-item "><Link style={{color:"#fff"}} to ="/">Home</Link></li>
        <li className="breadcrumb-item"><Link style={{color:"#fff"}} to ="/service">Pages</Link></li>
        <li className="breadcrumb-item  active" style={{color:"#fff"}} aria-current="page">Service</li>
      </ol>
    </nav>
  </div>
  </div>
</div>
{/* Page Header End */}

{/* service start  */}

<div class="container-fluid d-flex align-items-center justify-content-center position-relative flex-wrap" id="body">
      {card.map((ele, index) => (
        <div key={index} class="card d-flex position-relative flex-column">
            <div class='imgContainer'>
                <img src={ele.imgsrc}  alt='img'/>
            </div>
            <div class="content">
                <h3 style={{textAlign:"center"}}>{ele.heading}</h3>
                <p>{ele.paragraph}</p>
                <Link to={`/${ele.id}`}>
  <button type="button" className="btn btn-info" id='btn'>
    Explore More
  </button>
</Link>
            </div>
        </div>))}
        </div>
        {/* service end */}


        <div className='container-fluid' style={{backgroundColor:"#00ABF2"}}>
<div className='row p-5'>
    <div className='col-md-10'>
        <div className='heading'>
<h2 className='text-white'>LOOKING FOR QUALITY MADNI PAPER COMPANY</h2>
<p className='fs-5'>We provide Different High Quality Paper Services. GET A QUOTE IN 20% OFF</p>
        </div>

    </div>
    <div className='col-md-2'>
        <div className='quote'>
        <Link to="/contact">
            GET A QUOTE
          </Link>
        </div>

    </div>
</div>
</div>
      

    </Layout>
  )
}

export default Service
