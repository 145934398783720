import React, { useRef, useState } from 'react'
import Layout from '../Components/Layout'
import Button from '../Components/Button'
import emailjs from '@emailjs/browser';
import {   ToastContainer ,  toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const contactUser= (e) => {
        
    e.preventDefault();
    let userData = {name, email, phone, message}
    
    if (userData.name.length===0 || userData.email.length===0 || userData.message.length===0 || userData.phone.length<=10)
     {
        // alert("error");
        toast.error("Please complete the form!", {
            position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
          });
       
    }
    else{
        emailjs
        .sendForm(
            'service_lpqz1xc',
            'template_oqlp4a6',
            form.current, {
                publicKey: 'PITI51wO82caajCxe',
            })
            .then(
                () => {
                  console.log('SUCCESS!');
                },
                (error) => {
                  console.log('FAILED...', error.text);
                },
              );
              toast.success("Data send!", {
                position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
              });
              // Reset the form after successful submission
    //  form.reset();
    setName('') 
    setEmail('')
    setPhone('')
    setMessage('')
            }

  }

  return (
    <Layout>
      <Button/>
     
{/* Page Header Start */}
<div className="container-fluid page-header py-5 mb-5 " id='header'>
  <div className="container py-5">
  <div data-aos="fade-down">

    <h1 className="display-3 text-light mb-3 animated slideInDown text-center">Contact Us</h1>
  </div>
  <div data-aos="fade-right">

    <nav aria-label="breadcrumb  animated slideInDown"  style={{ display: 'flex', justifyContent: 'center' }}>
      <ol className="breadcrumb ">
        <li className="breadcrumb-item"><Link  style={{color:"#fff"}} to="/">Home</Link></li>
        <li className="breadcrumb-item"><Link style={{color:"#fff"}} to="/contact">Pages</Link></li>
        <li className="breadcrumb-item  active" aria-current="page" style={{color:"#fff"}}>Contact</li>
      </ol>
    </nav>
  </div>
  </div>
</div>
{/* Page Header End */}

     {/* Contact Form and Image Section */}
     <div className="container my-5">
        <div class="heading_container heading_center text-center mb-5">
        <h2>Get In <span style={{color:"#02672B"}}>Touch</span></h2>
      </div>
        <div className="row p-3" style={{ backgroundColor: 'rgb(102, 192, 244)' }}>
          {/* Contact Form Section */}

          <div className="col-lg-6 col-md-12 mb-4">
            
            <form className="contact-form text-dark mt-3" ref={form} onSubmit={contactUser}  >
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input onChange={(e) => setName(e.target.value)}
                  type="text" className="form-control" id="name" name="name" value={name} placeholder="Enter your name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="form-control" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="phone" className="form-control" id="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Enter your phone" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message :</label>
                <textarea
                  className="form-control" id="message" name="message" value={message}  onChange={(e) => setMessage(e.target.value)} rows="5" placeholder="Enter your message" required ></textarea>
              </div>
              <button type="submit" value="Send" className="btn btn-primary btn-block"  >Send Message</button>
            </form>
          </div>

          {/* map Section */}

          <div className="col-lg-6 col-md-12 mb-4 " >
          
          <iframe class="contact-map"  title="Your Unique and Descriptive Title" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3399.154122680031!2d74.31045577561372!3d31.57482117418919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDM0JzI5LjQiTiA3NMKwMTgnNDYuOSJF!5e0!3m2!1sen!2s!4v1716374116523!5m2!1sen!2s" width="100%" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  )
}

export default Contact
