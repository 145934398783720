import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import Layout from '../Components/Layout'
// import { Link } from 'react-router-dom';
import Button from '../Components/Button';
import { useParams } from 'react-router-dom';
import card from './Cardsapi';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Sticker = () => {
  //email configruration start
  const form = useRef();
  const [quantity, setQuantity] = useState(0)
  const [brand, setBrand] = useState("")
  const [size, setSize] = useState("")
  const [grame, setGrame] = useState("")
  const [category, setCategory] = useState("")
  const [packing, setPacking] = useState("")


  // const [x, setX] = useState(1)
  // const incHandler = (e) => {
  //   e.preventDefault();
  //   setX(x + 1)
  //   setQuantity(x + 1)
  // }
  // const decHandler = (e) => {
  //   e.preventDefault();
  //   if (x > 1) {
  //     setX(x - 1)
  //     setQuantity(x - 1)
  //   }
  // }
  const submitData = (e) => {
    e.preventDefault();


    let userData = { quantity, brand, size, grame, category, packing }

    if (userData.quantity.length === 0 || userData.size.length === 0 || userData.grame.length === 0 || userData.category.length === 0 || userData.packing.length === 0 || userData.brand.length === 0) {
      // alert("error");
      toast.error("Please fill all inputs!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
    else {
      emailjs
        .sendForm(
          'service_lpqz1xc',
          'template_j9xblif',
          form.current, {
          publicKey: 'PITI51wO82caajCxe',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
      toast.success("record Saved!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Reset the form after successful submission
      //  form.reset();
      console.log("quantity", quantity)
      console.log("brand :", brand)
      console.log("size", size)
      console.log("grame", grame)
      console.log("category", category)
      console.log("packing", packing)

    }

  }
  //email configuration end here//

  const { pId } = useParams()
  // if we use === below then we have to save id as a string in cardapi because === match its type as well 
  let filterData = card.find(x => x.id === pId)

  const [categoryy, setCategoryy] = useState(filterData.category[0].value);
  const [image, setImage] = useState('');


  useEffect(() => {
    // Fetch the image URL corresponding to the default category
    const image = {
      PVC: './assets/PVC.png',
      Artpaper: './assets/Art paper.png',
      Daimond: './assets/diamond.png',
      Golden: './assets/gold.png',
      Mattpaper: './assets/Matt paper.png',
      Artcard: './assets/Art card.png',
      Offsetpaper: './assets/Offset paper.png',
      Color:'./assets/fancy.png',
      Krft: './assets/krft.png',
      Matt: './assets/Matt.png',
      White: './assets/white.png',
      Warranty: './assets/warrenty.png',
      Every: './assets/every.png',
      Albaster: './assets/Alabaster.png',
      Melamine: './assets/Melamine.png',
      Butter: './assets/butter paper.png',
      Carbonless: './assets/carbonless paper.png',
      IMH: "./assets/imh7.png",
      A4: './assets/A4.png',
      Bleach: './assets/bleached paper.png',
      Transparent: './assets/transparent paper.png',
      Silver: './assets/silver.png',
      Silver50: './assets/Silver 50.png',
      Silver75: './assets/silver75.png',
      Dullsilver: './assets/dull silver.png',
      Silvertransparentback: './assets/silver transparent back.png',
      Dullgolden: './assets/dull golden.png',
      Computermatt: './assets/computer.png',

    };
    setImage(image[categoryy]);
  }, [categoryy]);

  const handleCategoryChange = (event) => {
    setCategoryy(event.target.value);
   
  };

  return (
    <Layout>
      <Button />

      <div className='container mt-3'>
        {/* info section */}
        <section className="about_section layout_padding mb-5 ">
          <div className="container mt-4 ">
            <div className="row">
              <div className="col-md-6">
                <div className="detail-box" style={{ marginTop: "0px" }}>
                  <div className="heading_container text-success">
                    <h2 >{filterData.heading} </h2>
                  </div>
                  <p>{filterData.heading1}</p>
                </div></div>
              <div className="col-md-6 ">
                <div className="img-box">
                  <img src={filterData.img}  alt='img' />
                </div>
              </div>
            </div>
          </div>

        </section>

        {/* end  section */}

        {/* //cart sections start */}

        <div className='container'>
          <div className='row'>
            {/* ///img div */}
            <div className='col-md-6 col-sm-12 '>
              {/* {imageUrl && <img src={imageUrl} alt="Selected" />} */}
              {image && <img class="postcard__img" src={image} alt="img" style={{ height: "450px", width: "550px" }} />}
            </div>
            {/* /////payment section */}

            <div className='col-md-6 col-sm-12'>
              <form className="contact-form text-dark mt-3" ref={form}   >
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-12 '>
                      <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">{filterData.heading}</h1>
                      <br/>
                      {/* <p class="leading-relaxed mt-3">
                        Product Code: {filterData.pcode}
                        <br />
                        SKU: {filterData.sku}
                      </p> */}
                      <div class="title-font font-medium text-2xl text-gray-900" value={quantity} name="quantity"
                       onChange={(e) => setQuantity(e.target.value)}
                      >
                        Quantity :
                         <input value={quantity} onChange={(e) => setQuantity(e.target.value)}
                  type="text" className="form-control" id="quantity" name="quantity"  placeholder="Enter product quantity" required /> 
                        {/* <button
                          onClick={incHandler}
                          className="btn btn-dark btn-sm">
                          +
                        </button>
                        &nbsp; {x}  &nbsp;
                        <button
                          onClick={decHandler}
                          className="btn btn-dark btn-sm">
                          -
                        </button> */}
                        </div>
                      <hr className='my-4' />
                    </div>
                  </div>
                  {/* ///row2 */}
                  <div className='row'>
                    <div className='row '>
                      <div className='col-lg-12 col-md-12 col-sm-5 '>
                        <div class="flex  items-center ms- ">
                          <span class="mr-3" value={brand} >
                            Brand
                          </span>
                          <div class="relative">
                            <select aria-label="Default select example" className="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" onChange={(e) => setBrand(e.target.value)} 
                            name="brand"
                            >
                              <option selected>--Select Brand--</option>
                              {filterData.brandoptions.map((opt, index) => (
                                <option key={index}>
                                  {opt}
                                </option>
                              ))}
                            </select>
                            <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div></div>
                    <div className='row mt-2 '>
                      <div className='col-md-12'>
                        <div class="flex  items-center ms-1">
                          <span class="mr-3 " value={size} >
                            Size
                          </span>
                          <div class="relative">
                            <select aria-label="Default select example" class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" onChange={(e) => setSize(e.target.value)}
                            name='size'>
                              <option selected>--Select Size--</option>
                              {filterData.sizeoption.map((option, index) => (
                                <option key={index}>
                                  {option}
                                </option>))}
                            </select>
                            <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div><br />
                        </div>
                      </div>
                    </div>
                    <div className='row mt-2 '>
                      <div className='col-lg-12 col-md-12 col-sm-5 '>
                        <div class="flex  items-center ms- ">
                          <span class="mr-3" value={grame} >Grame</span>
                          <div class="relative">
                            <select aria-label="Default select example" class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" onChange={(e) => setGrame(e.target.value)}
                            name='grame'>
                              <option selected>--Select Grame--</option>
                              {filterData.grameoption.map((opt, index) => (
                                <option key={index}>
                                  {opt}
                                </option>))}
                            </select>
                            <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div></div>
                    <div className='row mt-2 '>
                      <div className='col-md-12 col-sm-12'>
                        <div className="flex  items-center ">
                          <span class="mr-3" value={category} >Category</span>
                          <div class="relative" >
                            <select aria-label="Default select example" value={categoryy} onChange={(e) => {setCategory(e.target.value);
                            handleCategoryChange(e);}
                            } class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" name='category' >
                              {/* <option selected>--Select Category--</option> */}
                              {filterData.category.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.text}
                                </option>
                              ))}
                            </select>
                            <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-2 '>
                      <div className='col-md-12'>
                        <div class="flex  items-center ms-1">
                          <span class="mr-3 " value={packing} >Packing</span>
                          <div class="relative">
                            <select aria-label="Default select example" class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" onChange={(e) => setPacking(e.target.value)} placeholder='select' name='packing'>
                              <option selected>--Select Packing--</option>
                              {filterData.packing.map((option, index) => (
                                <option key={index}>
                                  {option}
                                </option>))}
                            </select>
                            <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /////row3 */}
                  <div className='row'>
                    <div className='col-md-12 '>
                      <hr className='my-4' />
                      <div class="text-center">
                        <button class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded" type="submit" value="Send" onClick={submitData}>
                          Order now
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>

        </div>
        {/* ///end */}
      </div>

      <ToastContainer />
    </Layout>
  )
}

export default Sticker
