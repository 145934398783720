import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Layout from '../Components/Layout';
import Button from '../Components/Button';

const Careerdetail= (props) => {
  return (
    <Layout>
      <Button/>
      <div className='color'>
      
      <div className="container  pt-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item ms-5" id='hed'><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
          </ol>
        </nav>
<div className='container'>
        <div className="row">
          <div id='JobDetails' className="card col-md-10 ">
            <div className="card-body  " id='border' >
              
              <h2 className="  text-dark mb-3">{props.title}</h2>
              <p className="card-text"><i class="fa-solid fa-location-crosshairs me-1"></i>{props.location}</p>
              <p className="card-text"><i class="fa-solid fa-house-laptop"></i>{props.company}</p>
              <div className='row align-items-center'>
                <p className='card-text  col-md-6' id='hed'><i class="fa-solid fa-money-bill-1-wave me-1"></i>{props.sal}</p>
                <p className="card-text">{`${props.des.slice(0,100)}...`}</p>
                <Link to={`/jobForm/${props.title},${props.company}`} type="button" className="btn btn-success ms-auto m-2 col-md-6" style={{width:"150px"}}> <i className="fa-regular fa-paper-plane fa-xl me-4" style={{ color: "#ffff", width:"10px"}} />  Apply Job</Link>
                <Outlet />
              </div>
              <hr className='border border-info' />
              {/* Skills */}
              <div className='container'>
                <h4 className='card-text'>Desired Skill:</h4>
                <div className="d-flex flex-wrap">
                  {props.skills.map((skill, index) => (
                    <span key={index} className="  mx-1">{skill}</span>
                  ))}
                </div>
              </div>
              <hr className='border border-info' />
              {/* Job Details */}
              <div className='container mt-4'>
                <h4 className='card-text'>Job Details:</h4>
                <div className='row'>
                  <div className='col-md-10'>
                    <p>{props.des}</p>
                    <p><strong>Location:</strong> {props.location}</p>
                    <p><strong>Compensation:</strong> {props.sal}</p>
                    <p><strong>Job Type:</strong> {props.JobType}</p>
                  </div>
                  {/* Add more details if necessary */}
                </div>
              </div>
            </div>
          </div>
        </div></div>
      </div>
      <br></br>
      </div>
     </Layout>
  );
};

export default Careerdetail;
