import React from 'react'
import { Link } from 'react-router-dom'

const Button = () => {
  return (
    <div>
      <div className='social' >
<Link to="https://wa.me/923107770424" target="_blank" rel="noopener noreferrer" >Whatsapp<i class="fa-brands fa-whatsapp" ></i></Link>
<Link to="mailto:hello@madnipaperlahore.com">Email<i class="fa-solid fa-envelope"></i></Link>
<Link to="https://www.facebook.com/madnipapermart">Facebook<i class="fa-brands fa-facebook"></i></Link>
<Link to="https://www.instagram.com/madnipapermart">Instagram<i class="fa-brands fa-instagram"></i></Link>
<Link to="https://www.youtube.com/@user-qq2yh7ux2x">Youtube<i class="fa-brands fa-youtube"></i></Link>
<Link to="https://www.linkedin.com/in/madni-paper-mart/">LinkedIn<i class="fa-brands fa-linkedin"></i></Link>
      </div>
    </div>
  )
}

export default Button
