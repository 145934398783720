
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import Career from './Pages/Career';
import Home  from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Service from './Pages/Service';
import Award from './Pages/Award';
// import Gallery from './Pages/Gallery';
import Sticker from './Pages/Sticker';
import CareerPage from "./Pages/CareerPage";
import Careerdetail from "./Pages/Careerdetail";
import jobData from "./Data/jobData";

import JobForm from "./Pages/Form";




function App() {
  return (
    <>
  <Router>
  <Routes>
            <Route path='/'element={<Home/>}/>
            <Route path='about'element={<About/>}/>
            <Route path='contact' element={<Contact/>}/>
           <Route path='service' element={<Service/>}/>
            <Route path='career'element={<Career/>}/>
            <Route path='careerpage'element={<CareerPage/>}/>
            {/* <Route path='gallery'element={<Gallery/>}/> */}
            <Route path='award'element={<Award/>}/>
            <Route path='/:pId' element={<Sticker/>} />
            {jobData.map((ele)=>(
             <Route
             key={ele.id}
             path={`/jobPlace${ele.id}`}
             element={<Careerdetail imgurl={ele.imageUrl} title={ele.title} des={ele.description} JobType={ele.jobType} sal={ele.salary} proType={ele.projectType} location={ele.location}
             company={ele.company} skills={ele.skills} />}
           />
          ))}
           <Route path='/jobForm/:title' element={<JobForm/>}/>
            {/* <Route path='/:jcd' element={<Careerdetail/>} /> */}
        </Routes>

  </Router>
    
    </>
   
  );
}

export default App;
