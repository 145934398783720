import React from 'react'

const Parallex = () => {
  return (
    <div>
      
       <header className='parallex-header'>
{/* container */}
<div className='container'>
  <div className='header-content'>
    

  </div>

</div>
{/* end of container */}
  </header>

 <div className='container'>
  <div className='parallexcontent row'>
    {/* ceo msg */}
<div className='col-md-12 left' id='left'>
<h1 className='text-dark text-center' style={{marginTop:"20px"}}>CEO Message</h1>
<p className='text-dark text-justify'>
In printing field and advertizing field has trustable name our goal is to achieve customer need and services. In future, company will expand more and create more facilities for ownerable customers. It will easy access for order payment transfer. Our mission is to become finishing printing and become new idea's in Pakistan. We will try to become Pakistan in for printing organization in the world.
</p>
</div>

{/* end here  */}



  </div>
 </div>
 </div>
    
  )
}

export default Parallex
