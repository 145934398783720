import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Button from '../Components/Button';
import 'aos/dist/aos.css';
import Aos from 'aos';
import Parallex from '../Components/Parallex';

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000});
    
  }, [])
  const[counterOn,setCounteron]= useState(false);
  return (
    <Layout>
      <Button/>

      {/* Page Header Start */}
<div className="container-fluid page-header py-5 mb-5 " id='about'>
  <div className="container py-5">
  <div data-aos="fade-down">

    <h1 className="display-3 text-light mb-3 animated slideInDown text-center">About Us</h1>
  </div>
  <div data-aos="fade-right">

    <nav aria-label="breadcrumb  animated slideInDown"  style={{ display: 'flex', justifyContent: 'center' }}>
      <ol className="breadcrumb ">
        <li className="breadcrumb-item"><Link  style={{color:"#fff"}} to="/">Home</Link></li>
        <li className="breadcrumb-item"><Link style={{color:"#fff"}} to="/about">Pages</Link></li>
        <li className="breadcrumb-item  active"style={{color:"#fff"}} aria-current="page">About</li>
      </ol>
    </nav>
  </div>
  </div>
</div>
{/* Page Header End */}


{/* Feature Start */}
<div className="container-xxl py-5">
  <div className="container">
    <div data-aos="fade-up" className="row g-5">
      <div  className="col-md-6 col-lg-3 ">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center justify-content-center bg-light" style={{width: 60, height: 60}}>
            <i className="fa fa-user-check fa-2x text-primary" />
          </div>
          <h1 className="display-1 text-secondary mb-0">01</h1>
        </div>
        <h5>Creative Designers</h5>
      </div>
      <div  className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center justify-content-center bg-light" style={{width: 60, height: 60}}>
            <i className="fa fa-check fa-2x text-primary" />
          </div>
          <h1 className="display-1 text-secondary mb-0">02</h1>
        </div>
        <h5>Quality Products</h5>
      </div>
      <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center justify-content-center bg-light" style={{width: 60, height: 60}}>
            <i className="fa fa-drafting-compass fa-2x text-primary" />
          </div>
          <h1 className="display-1 text-secondary mb-0 ">03</h1>
        </div>
        <h5>Free Consultation</h5>
      </div>
      <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center justify-content-center bg-light" style={{width: 60, height: 60}}>
            <i className="fa fa-headphones fa-2x text-primary" />
          </div>
          <h1 className="display-1 text-secondary mb-0">04</h1>
        </div>
        <h5>Customer Support</h5>
      </div>
    </div>
  </div>
</div>
{/* Feature end*/}


{/* About Start */}
<div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
  <div className="container about px-lg-0">
    <div className="row g-0 mx-lg-0">
      <div className="col-lg-6 ps-lg-0 col-md-12" style={{minHeight: 400}}>
        <div className="position-relative h-100">
          <img className="position-absolute img-fluid mt-4" src="./assets/about/3.png" style={{objectFit: 'cover', }} alt='img' />
        </div>
      </div>
      <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
        <div className="p-lg-5 pe-lg-0">
          <div className="section-title text-start">
            <h1 className="display-5 mb-4" style={{color:"#00ABF2" }}>About Us</h1>
          </div>
          <p className="mb-4 pb-2">Madni Paper Mart is your trusted source for premium paper products, delivering quality, reliability, and affordability in every sheet. With a commitment to excellence and customer satisfaction, we offer a comprehensive range of solutions tailored to meet diverse needs. Choose Madni Paper Mart for superior quality and service you can depend on.</p>
          <div className="row g-4 mb-4 pb-2">
            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{width: 60, height: 60}}>
                  <i className="fa fa-users fa-2x text-primary" />
                </div>
                <div className="ms-3">
                <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
                  <h2 className="text-primary mb-1" data-toggle="counter-up">{counterOn && <CountUp start={0} end={1234} duration={2} delay={0} />}</h2>
                  <p className="fw-medium mb-0">Happy Clients</p>
                  </ScrollTrigger>
                </div>
              </div>
            </div>
            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{width: 60, height: 60}}>
                  <i className="fa fa-check fa-2x text-primary" />
                </div>
                <div className="ms-3">
                 <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
                  <h2 className="text-primary mb-1" data-toggle="counter-up">{counterOn && <CountUp start={0} end={1234} duration={2} delay={0} />}</h2>
                  <p className="fw-medium mb-0">Projects Done</p>
                  </ScrollTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* About End */}


<Parallex/>

{/* Goal section start */}

<div className='container-fluid mt-3'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */} 
          <div data-aos="fade-up" className=" col-md-6 mt-4" id="A1">
            <div className="my-text mt-5 ms-5">
          <h2 className='heading text-center m-3 fs-1'><span >Our</span> Goal</h2>
              <p className='justify  text-dark'>
              At Madni Paper Mart, our goal is to consistently provide our customers with high-quality paper products that meet their needs while maintaining a commitment to excellence, reliability, and affordability. We strive to exceed expectations, foster lasting partnerships, and contribute positively to our community and the environment.</p>
            </div>
          </div>

          {/* Right Side Column with Image */}
          
          <div data-aos="fade-up" className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5 mt-5">
            <img src="./assets/about/goal.png" className="img-fluid" alt="Goal" />
          </div></div>          
        </div>
      </div>
         
    </Layout>
  )
}

export default About
