import React from 'react'
import Layout from './../Components/Layout';
import Button from '../Components/Button';
import card from './Cardsapi';
import { Link } from 'react-router-dom';

const Award = () => {
  return (
    <Layout>
      <Button/>
       {/* Page Header Start */}
<div className="container-fluid page-header py-5 mb-5 mt-3" id='header'>
  <div className="container py-5">
  <div data-aos="fade-down">

    <h1 className="display-3 text-light mb-3 animated slideInDown">Awards</h1>
  </div>
  <div data-aos="fade-right">

    <nav aria-label="breadcrumb  animated slideInDown">
      <ol className="breadcrumb text-light">
        <li className="breadcrumb-item "><Link className="text-light" to ="/">Home</Link></li>
        <li className="breadcrumb-item"><Link className="text-light" to ="/award">Pages</Link></li>
        <li className="breadcrumb-item text-light active" aria-current="page">Awards</li>
      </ol>
    </nav>
  </div>
  </div>
</div>
{/* Page Header End */}

{/* award  map*/}
<div className='container'>
            <div className="row">
              {card.map((ele, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-5">
                  <div data-aos="zoom-in-up" className="custom-card card" id="card" style={{ width: '100%' }}>
          
                    <img src={ele.imgsrc}  className="custom-card-img-top card-img-top" alt="Course Preview" />
                    
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* end map */}
    </Layout>
  )
}

export default Award
