import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    
      <div className='footerbody ' >
  <footer className="site-footer  mt-5"  id='footer'>
  <div className="container " >
    <div className="row" >
      <div className="col-lg-3 col-md-6 ">
        {/* Contact Info*/}
        <section className="widget widget-dark-skin ">
          <h3 className="widget-title text-dark">Get In Touch With Us</h3>
          <p className="text-dark">Phone: +923107770424</p>
          <ul className="list-unstyled text-sm text-dark">
            <li><span className="opacity-50">Monday-Saturday:</span><br/>10.00 am - 7.00 pm</li>
          </ul>
          <p><Link className="navi-link-light text-dark" to="#">hello@madnipaperlahore.com</Link></p><Link className="social-button shape-circle sb-facebook sb-light-skin" to="/"><i className="socicon-facebook" /></Link><Link className="social-button shape-circle sb-twitter sb-light-skin" to="/"><i className="socicon-twitter" /></Link><Link className="social-button shape-circle sb-instagram sb-light-skin" to="/"><i className="socicon-instagram" /></Link><Link className="social-button shape-circle sb-google-plus sb-light-skin" to="/"><i className="socicon-googleplus" /></Link>
        </section>
      </div>
      <div className="col-lg-3 col-md-6">
        {/* social media App Buttons*/}
        <section className="widget widget-dark-skin">
          <h3 className="widget-title text-dark">Our Social Media App</h3><Link  to="https://www.facebook.com/madnipapermr"><button className=' text-dark  mb-1'><i class="fa-brands fa-facebook-f me-4 text-dark "></i>Facebook</button> </Link><br/>
          <Link  to="https://www.instagram.com/madnipapermart"><button className=' text-dark mb-1'><i class="fa-brands fa-square-instagram me-3 text-dark"></i>Instagram</button> </Link><br/>
          <Link  to="https://www.linkedin.com/in/madni-paper-mart/"><button className=' text-dark mb-1'><i class="fa-brands fa-linkedin-in text-dark me-3"></i>  LinkedIn</button> </Link>
          <br/><Link  to="https://www.youtube.com/@user-qq2yh7ux2x"><button className=' text-dark mb-4'><i class="fa-brands fa-youtube me-3"></i>Youtube</button> </Link>
        </section>
      </div>
      <div className="col-lg-3 col-md-6">
        {/* Quick section*/}
        <section className="widget widget-links widget-dark-skin ">
          <h3 className="widget-title text-dark">Quick Link</h3>
          <ul >
            <li><Link to="/" className='text-dark' style={{marginLeft:"-28px"}}>Home</Link></li>
            <li><Link to="/about" className='text-dark' style={{marginLeft:"-28px"}}>About </Link></li>
            <li><Link to="/service" className='text-dark' style={{marginLeft:"-28px"}}>Services</Link></li>
            <li><Link to="/contact" className='text-dark' style={{marginLeft:"-28px"}}>Contact Us</Link></li>
            {/* <li><Link to="/gallery" className='text-dark' style={{marginLeft:"-28px"}}>Gallery</Link></li>
            <li><Link to="/award" className='text-dark' style={{marginLeft:"-28px"}}>Awards</Link></li> */}
            <li><Link to="/career" className='text-dark' style={{marginLeft:"-28px"}}>Career</Link></li>
          </ul>
        </section>
      </div>
      <div className="col-lg-3 col-md-6">
        {/* logo*/}
        <img src='./assets/logo.png' className='mt-5 p-4' alt='img'/>
     
      </div>
    </div>
    <hr className="hr-light mt-2 margin-bottom-2x" />
    <div className="row">
      <div className="col-md-7 padding-bottom-1x" />
      <div className="col-md-5 padding-bottom-1x">
        <div className="margin-top-1x hidden-md-up" />
       
      </div>
    </div>
    {/* Copyright*/}
    {/* <hr className='text-secondary'/> */}
    <p className="footer-copyright text-dark text-center">© All rights reserved. Madnipaperlahore</p>
  </div>
</footer>
</div>
    
  )
}

export default Footer
